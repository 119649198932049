import React, { useContext } from 'react';
import {
  Avatar, Button, Col, Dropdown, Row,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import LangPicker from '../../LangPicker';
import './styles.scss';
import { getMediaLink, getTranslatorSchema } from '../../../utils/tools';
import { AppContext } from '../../../contexts';
import routesDictionary from '../../../routes/routesDict';
import { ReactComponent as MenuIcon } from '../../../resources/icons/menuIcon.svg';
// import { ReactComponent as BellIcon } from '../../../resources/icons/bellIcon.svg';
import { items } from './constants';

function ProfileHeader(props) {
  const { setLoginState } = props;
  const { t } = useTranslation();
  const translator = getTranslatorSchema('header', t);
  const { state, dispatch } = useContext(AppContext);
  const { me } = state;
  const navigate = useNavigate();

  return (
    <Row className="profile-header" align="middle" wrap={false}>
      <Col className="profile-header__text">
        <span>
          {translator('welcome')}
        </span>
      </Col>
      <Col className="profile-header__text profile-header__text--user ml-1">
        <span>
          {`${me.firstName} ${me.lastName}`}
        </span>
      </Col>
      <Col>
        <Avatar
          className="profile-header__avatar"
          {...me.profilePicture
            ? { src: getMediaLink(me.profilePicture) }
            : { icon: <UserOutlined /> }}
          size={40}
          onClick={() => navigate(routesDictionary.profile.router)}
        />
      </Col>
      {/* <Col className="profile-header__notification"> */}
      {/*   <Row align="middle"> */}
      {/*     <BellIcon /> */}
      {/*   </Row> */}
      {/*   <div className="profile-header__number" /> */}
      {/* </Col> */}
      <Col>
        <LangPicker />
      </Col>
      <Col className="profile-header__menu-icon">
        <Row align="middle" wrap={false}>
          <Dropdown
            menu={{
              items: items(
                translator,
                navigate,
                setLoginState,
                me.userType,
                me.isApprover,
                me?.isStaff,
                dispatch,
              ),
            }}
            placement="bottomRight"
            trigger={['click']}
            style={{ width: 255 }}
            dropdownAlign={{ offset: [40, 400] }}
          >
            <Button type="text">
              <Row align="middle">
                <MenuIcon />
              </Row>
            </Button>
          </Dropdown>
        </Row>
      </Col>
    </Row>
  );
}

export default ProfileHeader;
