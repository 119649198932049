import {
  API_URL,
  AUTH_TOKEN,
  BACKEND_LANGUAGE, BACKEND_URL,
  DEFAULT_LANGUAGE,
  EXPIRED_DAY_COOKIE,
  I18LANGUAGE,
  REFRESH_TOKEN,
} from '../settings/constants';
import banner, { bannerTypes } from './banner';
// import getMoment from './customMoment';

// const moment = getMoment();

/**
 * Function to revoke refreshTokens
 * @function revokeToken
 * @param url Backend Url
 * @param refreshToken refresh token
 * @param onFinish Extra function to execute
 */
export const revokeToken = (url, refreshToken, onFinish = () => {
}) => {
  if (!refreshToken) {
    return;
  }

  const revokeXhr = new XMLHttpRequest();
  const revokeObj = {
    operationName: null,
    query: `mutation ($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
    revoked
    }
  }`,
    variables: { refreshToken },
  };

  revokeXhr.open('POST', url);
  revokeXhr.setRequestHeader('Content-Type', 'application/json');
  revokeXhr.onload = () => {
    onFinish();
  };

  revokeXhr.onerror = () => {
  };
  revokeXhr.send(JSON.stringify(revokeObj));
};

/**
 * Function to check if a value is undefined.
 * @function isUndefined
 * @param value - Any value that can be undefined.
 * @returns {Boolean}
 */
export const isUndefined = (value) => typeof value === 'undefined';

/**
 * Funtion to check if a value is null.
 * @function isNull
 * @param value - Any value that can be null.
 * @returns {Boolean}
 */
export const isNull = (value) => value === null;

/**
 * Function to set login tokens
 * @function setLoginTokens
 * @param accessToken: access token provided by backend
 * @param refreshToken: refresh token provided by backend
 * @param cookie: to keep tokens or not
 */
export const setLoginTokens = (accessToken, refreshToken, cookie = false) => {
  localStorage.setItem(AUTH_TOKEN, accessToken);
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
  if (cookie) {
    localStorage.setItem(EXPIRED_DAY_COOKIE, 'true');
  }
};

/**
 * Function to get specific keys from a object
 * @function pickKeys
 * @param obj: object to be iterated
 * @param props: list of keys
 * @returns {Object || undefined}
 */

export const pickKeys = (obj, props) => {
  if (!obj || !props) return undefined;
  const picked = {};

  props.forEach((prop) => {
    picked[prop] = obj[prop];
  });

  return picked;
};

/**
 * Function to get an array of integer values by key
 * @function getIntegerListByKey
 * @param list: Object
 * @param key: Key name
 */

export const getIntegerListByKey = (list, key) => list.map(
  (values) => (isNull(values[key]) ? 0 : parseFloat(values[key])),
);

/**
 * Function to get an array of values by key
 * @function getValueListByKey
 * @param list: Object
 * @param key: Key name
 */

// export const getValueListByKey_old = (list = [], key) => list.map(
//   (values) => values[key],
// );

export const getValueListByKey = function (key, list = []) {
  list.map((values) => values[key]);
};

/**
 * function to get the number of decimal places of a number
 * @param num number
 * @returns {number} number of decimal places
 */
export const getDecimalCount = (num) => {
  const numStr = parseFloat(num).toString();
  if (numStr.includes('.')) {
    const count = numStr.split('.')[1].length;
    return count > 0 ? 2 : count;
  }
  return 2;
};

/**
 * Function to format a number to germany style.
 * @function numToFormat
 * @param number: Number
 * @param decimals
 * @param type
 * @param currency: If is necessary add symbol
 * @param maxDataFloat: To limit the max value that will display decimals
 * @param showDecimals: To display all decimals of the number
 */
export const numToFormat = (
  number,
  decimals = 0,
  type = 'default',
  currency = 'EUR',
  maxDataFloat = null,
  showDecimals = false,
) => {
  let value = number;
  let decimalsQuantity = showDecimals ? getDecimalCount(number) : decimals;

  if (typeof number === 'string') {
    value = parseFloat(number);
  }
  if (isNull(value)) return 'N/V';
  if (Number.isNaN(value) || !Number.isFinite(value)) return 'N/V';

  if (isUndefined(value)) return '';

  if (type === 'percent') {
    value /= 100;
  }

  if ((maxDataFloat && Math.abs(value) > maxDataFloat)) {
    decimalsQuantity = 0;
  }

  const types = {
    default: {
      minimumFractionDigits: decimalsQuantity,
      maximumFractionDigits: decimalsQuantity,
    },
    currency: {
      style: 'currency',
      currency,
      minimumFractionDigits: decimalsQuantity,
      maximumFractionDigits: decimalsQuantity,
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: decimalsQuantity,
      maximumFractionDigits: decimalsQuantity,
    },
    custom: {
      minimumFractionDigits: decimalsQuantity,
      maximumFractionDigits: decimalsQuantity,
    },
  };

  if (type === 'custom') {
    return value.toLocaleString().concat(' ', currency);
  }

  return value.toLocaleString('de-DE', types[type]);
};

/**
 * Function to change null to zero.
 * @function nullZero
 * @param value: Value
 */
export const nullZero = (value) => (isNull(value) ? '-' : value);

/**
 * Function to check if a value is undefined or null.
 * @function nullOrUndefined
 * @param value: Value
 */
export const nullOrUndefined = (value) => (isNull(value) || isUndefined(value));

export const nullOrUndefinedOrEmpty = (value) => (nullOrUndefined(value) || value === '');

/**
 * Function to get translation schema from i18next
 * @param {string} schemaName
 * @param {function} t
 */

// export const getTranslatorSchema_old = (schemaName, t) => (key, global = false, variables) => {
//   if (global) return t(`global.${key}`, variables);

//   return t(`${schemaName}.${key}`, variables);
// };

export const getTranslatorSchema = (schemaName, t) => (key, global = false, variables) => {
  if (global) return t(`global.${key}`, variables);

  return t(`${schemaName}.${key}`, variables);
};

/**
 * Function to set backend errors on a form
 * @param {object} data
 * @param {instance} form
 */
export const manageFeedback = (data, form = null) => {
  if (data.feedback) {
    const { type, message } = data.feedback;
    banner[bannerTypes[type]](message);
  }
  if (isNull(data)) return true;
  const { fieldErrors } = data;
  if (fieldErrors && form) {
    form.setFields(fieldErrors.map((error) => ({
      name: error.field,
      errors: error.messages,
    })));

    return true;
  }

  return !isUndefined(data.success) && !data.success;
};

/**
 * Function to get auth token
 * @function getAuthToken
 * @returns {String}
 */
export const getAuthToken = () => {
  const localToken = localStorage.getItem(AUTH_TOKEN);
  const sessionToken = sessionStorage.getItem(AUTH_TOKEN);

  return isNull(localToken) ? sessionToken : localToken;
};

/**
 * Function to get refresh token
 * @function getRefreshToken
 * @returns {String}
 */
export const getRefreshToken = () => {
  const localToken = localStorage.getItem(REFRESH_TOKEN);
  const sessionToken = sessionStorage.getItem(REFRESH_TOKEN);

  return isNull(localToken) ? sessionToken : localToken;
};

/**
 * Function to get app language
 * @function getLanguage
 * @returns {String}
 */
export const getLanguage = () => {
  const language = localStorage.getItem(I18LANGUAGE);

  return isNull(language) ? DEFAULT_LANGUAGE : language;
};

/**
 * Function to get backend language
 * @function getBackLanguage
 * @returns {String}
 */
export const getBackLanguage = () => (BACKEND_LANGUAGE[getLanguage()]);

/**
 * Function to logout
 * @function logout
 * @param {*} setLoginState Change state function.
 * @param {*} setCurrentTenant Change current tenant function
 */
export const logout = (setLoginState = () => {}) => {
  const deleteLocal = () => {
    localStorage.clear();
    sessionStorage.clear();
    setLoginState(false);
  };
  revokeToken(API_URL, getRefreshToken(), deleteLocal);
};

/**
 * Function to get global password regex
 * @function getGlobalPasswordRegex
 */
export const getGlobalPasswordRegex = () => /(?=.*[a-zäöüß])(?=.*[A-ZÄÖÜ])(?=.*\d)/;

/**
 * Function to get global size password
 * @function getGlobalPasswordMinSize
 */
export const getGlobalPasswordMinSize = () => 8;

/**
 * Function to get refresh token expired date
 * @function getExpiredRefreshToken
 */
export const getExpiredRefreshToken = () => localStorage.getItem(EXPIRED_DAY_COOKIE);

/**
 * Function to change null values to N/V
 * @function nullToNV
 */
export const nullToNV = (value) => {
  if (value && value !== '-') return value;

  return 'N/V';
};

/**
 * Function to check if an string can be a number
 * @param {object} str the string object
 */
export const isNumeric = (str) => {
  if (typeof str !== 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
};

export const rgb = (values) => `rgb(${values.join(', ')})`;

/**
 * Check if a number has decimals
 * @param number
 * @returns {boolean}
 */
export const checkNumberHasDecimals = (number) => {
  if (Number.isNaN(number)) return false;
  return number % 1 !== 0;
};

export const getMediaLink = (mediaUrl) => `${BACKEND_URL}media/${mediaUrl}`;

export const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => resolve(reader.result);

  reader.onerror = (error) => reject(error);
});

export const isString = (value) => typeof value === 'string';

const mobileBreakpoints = ['sm', 'md', 'xs'];

export const isMobileBreakpoint = (value) => mobileBreakpoints.includes(value);

export const dummyRequest = async ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const getData = (data) => data.map((item) => ({ value: item.id, label: item.name }));
/**
 * Check resolution image
 * @param file
 * @param pixels
 * @returns {Promise<unknown>}
 */
export const checkImageResolution = (file, pixels) => new Promise((resolve, reject) => {
  const img = new Image();
  img.src = window.URL.createObjectURL(file);
  img.onload = () => {
    const width = img.naturalWidth;
    const height = img.naturalHeight;
    if (width < pixels || height < pixels) {
      resolve(true);
    }
    resolve(false);
  };
  img.onerror = reject;
});

/**
 * Check if object is empty
 * @param obj
 * @returns {boolean}
 */
export const isObjectEmpty = (obj) => !Object.keys(obj).length;

/**
 * Check if video exist
 * @param videoUrl
 * @returns {Promise<boolean>}
 */
export const videoExists = async (videoUrl) => {
  try {
    const response = await fetch(videoUrl, { method: 'HEAD' });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

/**
 * Scroll two components same time
 * @param firstRef
 * @param secondRef
 */
export const getScrollValue = (firstRef, secondRef) => {
  // eslint-disable-next-line
  firstRef.current.scrollLeft = secondRef.current.scrollLeft;
};

/**
 * Number format
 * @param number
 * @returns {string|string}
 */
export const numberFormat = (number) => (
  number
    ? `$${parseFloat(number).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    : '-');
