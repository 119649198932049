import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import './styles.scss';
import { Button, Col, Row } from 'antd';
import { ReactComponent as ArrowIcon } from '../../../resources/icons/arrowDown.svg';
import { AppContext } from '../../../contexts';
import { ReactComponent as DeleteIcon } from '../../../resources/icons/deleteIcon.svg';

function Header() {
  const [showButtons, setShowButtons] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const { variables } = state;
  const scrollImagesRef = useRef(null);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if (scrollImagesRef.current.scrollWidth <= scrollImagesRef.current.clientWidth) {
      setShowButtons(false);
    } else {
      setShowButtons(true);
    }
  }, [scrollImagesRef, windowSize]);

  const handleSelectCategory = (value) => {
    dispatch({
      type: 'updateVariables',
      data: {
        ...state,
        variables: {
          ...state.variables,
          selectedCategory: variables.selectedCategory === value ? null : value,
        },
      },
    });
  };
  const categories = variables?.categories || [];

  const categoriesList = [
    variables?.selectedCategory && {
      value: 'deselect',
      label: (
          <Button type="text" onClick={() => handleSelectCategory(variables.selectedCategory)}>
            <Row align="middle">
              <DeleteIcon style={{ fontSize: '17.5px' }} />
            </Row>
          </Button>
      ),
    },
    ...categories,
  ]
    .filter(Boolean);

  return (
    <Row className="home-header" align="middle">
      {showButtons && (
        <Col span={1} className="home-header__col">
          <Button type="text" onClick={() => scrollImagesRef.current.scrollBy(-200, 0)}>
            <Row align="middle">
              <ArrowIcon className="home-header__arrow home-header__arrow--left" />
            </Row>
          </Button>
        </Col>
      )}
      <Col span={showButtons ? 22 : 24} className="home-header__col">
        <div className="scroll-images" ref={scrollImagesRef} style={{ ...!showButtons && { justifyContent: 'space-around' } }}>
          {categoriesList?.map((category, index) => (
            <>
              {category.value === 'deselect'
                ? category.label
                : (
                  <Col key={category.value} className="home-header__item">
                    {/* eslint-disable-next-line */}
                  <div
                    className={`home-header__button ${category.value === variables?.selectedCategory && 'home-header__button--selected'}`}
                    onClick={() => handleSelectCategory(category.value)}
                  >
                    <span>
                      {category.label}
                    </span>
                  </div>
                  </Col>
                )}
              {
                // eslint-disable-next-line
                index !== (categoriesList?.length - 1) && (
                <Col className="home-header__divider">
                  <span>
                    |
                  </span>
                </Col>
                )
                  }
            </>
          ))}
        </div>
      </Col>
      {showButtons && (
        <Col span={1} className="home-header__col">
          <Button type="text" onClick={() => scrollImagesRef.current.scrollBy(200, 0)}>
            <Row align="middle">
              <ArrowIcon className="home-header__arrow home-header__arrow--right" />
            </Row>
          </Button>
        </Col>
      )}
    </Row>
  );
}

export default Header;
