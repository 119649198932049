export const DATE_FORMAT = 'YYYY-MM-DD';
/** global: DATE_FORMAT official format 'DD.MM.YYYY' */
export const DATE_FORMAT_MONTH = 'MM/YYYY';
/** global: DATE_FORMAT official format 'DD.MM.YYYY' */
export const MONTHLY_FORMAT = 'YYYY-MM';
/** global: DATE_FORMAT official format 'DD.MM.YYYY' */
export const YEARLY_FORMAT = 'YYYY';
/** global: DATE_FORMAT official format 'DD.MM.YYYY' */
export const AUTH_TOKEN = 'Marketplace-(bqh@%!str-2v@i@84pk&e$or_if-m2xo!q';
/** global: AUTH_TOKEN */
export const REFRESH_TOKEN = 'Marketplace-rr-(!str-2v@i@84pk&e$or_if-m2xq';
/** global: REFRESH_TOKEN */

export const API_URL = typeof process.env.REACT_APP_DEV_API_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_API_URL : false;
/** global: API_URL */
export const WS_URL = typeof process.env.REACT_APP_DEV_WS_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_WS_URL : false;

export const LANGUAGE_LIST = ['EN', 'DE'];
/** global: LANGUAGE_LIST */
export const I18LANGUAGE = 'app-language';
export const DEFAULT_LANGUAGE = 'EN';
/** global: DEFAULT_LANGUAGE */
export const BACKEND_LANGUAGE = {
  EN: 'en',
  DE: 'de',
};
export const EXPIRATION_DATE_FORMAT = 'DD.MM.YYYY';
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const TABLE_DATE_FORMAT = 'MMM YYYY';

export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

/** Possible modes: 'prod', 'dev', 'test', 'local */
export const APP_MODE = process.env.REACT_APP_MODE || 'dev';
export const APP_MODES = {
  production: 'prod',
  development: 'dev',
  test: 'test',
  local: 'local',
};

/** This names has to be the same has the possible modes */
// Took from Django documentation
export const LIMITS_DECIMAL = (digits = 12, decimals = 4) => {
  if (digits > 12 || decimals > 4) return 'ParseFlot does not support bigger values';

  const biggestDecimal = `${'9'.repeat(digits)}.${'9'.repeat(decimals)}`;
  const numberLimit = parseFloat(biggestDecimal);
  return [-numberLimit, numberLimit];
};

// export const LIMITS_INTEGER = [-2147483647, 2147483647];
export const LIMITS_INTEGER = [-999999999, 999999999];
// export const LIMITS_SMALL_INTEGER = [-32767, 32767];
export const LIMITS_SMALL_INTEGER = [-9999, 9999];

export const LIMITS_POSITIVE_INTEGER = [0, 999999999];
export const LIMITS_POSITIVE_SMALL_INTEGER = [0, 9999];

export const EXPIRED_DAY_COOKIE = 'ExpiredDay';

export const BACKEND_URL = process.env.REACT_APP_DEV_BACKEND_URL;

export const MAX_PRODUCT_CONTENT_IMAGES = 10;

export const USER = 1;
export const CONTENT_PROVIDER = 2;

export const DRAFT = 1;
export const IN_APPROVAL_PROCESS = 2;
export const APPROVED = 3;
export const REJECTED = 4;
export const NEED_ADMIN_DECISION = 5;

export const IN_REVISION_APPROVED_DECISION = 1;
export const APPROVED_APPROVED_DECISION = 2;
export const REJECTED_APPROVED_DECISION = 3;
export const CANCELED_APPROVED_DECISION = 4;

export const HOTJAR_HJID = parseInt(process.env.REACT_APP_HOTJAR_HJID);
export const HOTJAR_HJSV = parseInt(process.env.REACT_APP_HOTJAR_HJSV);

