import { logout } from '../../../utils/tools';
import routesDictionary from '../../../routes/routesDict';
import { CONTENT_PROVIDER } from '../../../settings/constants';

export const items = (
  translator,
  navigate,
  setLoginState,
  userType,
  isApprover,
  isAdmin,
  dispatch,
) => [
  {
    label: translator('profileSettings'),
    key: '0',
    onClick: () => navigate(routesDictionary.profile.router),
  },
  {
    type: 'divider',
  },
  {
    label: translator('myContentProducts'),
    key: '4',
    onClick: () => navigate(routesDictionary.productContentOwn.router),
  },
  ...(isApprover || isAdmin) ? [
    {
      label: translator('productVerification'),
      key: '3',
      onClick: () => navigate(routesDictionary.productContentVerification.router),
    },
  ] : [],
  {
    type: 'divider',
  },
  {
    label: translator('logout'),
    key: '2',
    onClick: () => {
      logout(setLoginState);
      dispatch({ type: 'clearInfo' });
    },
  },
];

export default {};
