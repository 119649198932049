import React from 'react';
import { Col, Layout, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../resources/img/logo.svg';
import { ReactComponent as FileCoinResponsiveLogo } from '../../resources/icons/logo.svg';
import './styles.scss';
import ProfileHeader from './ProfileHeader';
import routesDictionary from '../../routes/routesDict';
import SubHeader from './SubHeader';
import useCurrentBreakpoint from '../../customHooks/useCurrentBreakpoint';
import { isMobileBreakpoint } from '../../utils/tools';
import AuthHeader from './AuthHeader';

const { Header: AntdHeader } = Layout;

function Header(props) {
  const {
    isLogin, setLoginState, isPersistentRoute, hasSubHeader,
  } = props;
  const navigate = useNavigate();
  const breakPoint = useCurrentBreakpoint();

  if (!(isPersistentRoute || isLogin)) return null;

  return (
    <AntdHeader className={`antd-header--${hasSubHeader ? 'sub-header' : 'sub-menu'} `}>
      <Row className="header" align="middle" wrap={false}>
        <Col>
          <Row align="middle">
            <Logo
              onClick={() => navigate(routesDictionary.home.router)}
              className="header__logo"
            />
            {/* {isMobileBreakpoint(breakPoint) */}
            {/*   ? ( */}
            {/*     <FileCoinResponsiveLogo */}
            {/*       onClick={() => navigate(routesDictionary.home.router)} */}
            {/*       className="header__logo--responsive" */}
            {/*     /> */}
            {/*   ) */}
            {/*   : ( */}
            {/*     <Logo */}
            {/*       onClick={() => navigate(routesDictionary.home.router)} */}
            {/*       className="header__logo" */}
            {/*     /> */}
            {/*   )} */}
          </Row>
        </Col>
        <Col className="header__profile">
          {isLogin
            ? <ProfileHeader setLoginState={setLoginState} />
            : <AuthHeader />}
        </Col>
      </Row>
      {hasSubHeader && (
      <SubHeader />
      )}
    </AntdHeader>
  );
}

export default Header;