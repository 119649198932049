import React, { Suspense } from 'react';
import {
  Routes, Route, useLocation, Navigate,
} from 'react-router-dom';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import AppContextProvider from '../../contexts';
import routes from '../../routes/routeList';
import MakeRouteWithSubRoutes from '../../routes';
import { getTranslatorSchema } from '../../utils/tools';
import Header from '../../components/Header';
import routesDictionary from '../../routes/routesDict';
import persistentHeaders, { getSplitRoute } from '../../components/Header/constants';

const { Content } = Layout;

function Router(props) {
  const {
    isLogin,
    setLoginState,
  } = props;
  const { pathname } = useLocation();

  const hasSubHeader = pathname === routesDictionary.home.router;
  const isPersistentRoute = persistentHeaders.includes(getSplitRoute(pathname)) || hasSubHeader;

  return (
    <Layout
      className={`base ${!isLogin && 'vh-100'}`}
    >
      <AppContextProvider
        setLoginState={setLoginState}
        isLogin={isLogin}
      >
        <Suspense fallback={<Loading />}>
          <Header
            isLogin={isLogin}
            setLoginState={setLoginState}
            isPersistentRoute={isPersistentRoute}
            hasSubHeader={hasSubHeader}
          />
          <Content
            style={{ position: 'relative' }}
            className={(isLogin || isPersistentRoute) ? 'custom-content' : ''}
          >
            <Routes>
              {routes.map((route) => (
                <Route
                  path={route.path}
                  key={route.path}
                  element={(
                    <MakeRouteWithSubRoutes
                      key={route.path}
                      component={route.component}
                      path={route.path}
                      setLoginState={setLoginState}
                      title={route.title}
                      type={route.type}
                      isLogin={isLogin}
                      module={route.module}
                    />
                    )}
                />
              ))}
              <Route
                path="*"
                element={<Navigate replace to={routesDictionary.home.router} />}
              />
            </Routes>
          </Content>
          {isLogin && <Footer /> }
        </Suspense>
      </AppContextProvider>
    </Layout>
  );
}

Router.propTypes = {
  isLogin: PropTypes.bool,
  // setLoginState: PropTypes.func.isRequired,
};

Router.defaultProps = {
  isLogin: false,
};

export default Router;
