import React from 'react';
// TODO: Remove/Resolve comments
// import PropTypes from 'prop-types';

const propTypes = {
  // message: PropTypes.string.isRequired,
  // type: PropTypes.string.isRequired,
  // closable: PropTypes.bool,
  // fixedBanner: PropTypes.bool,
};

const defaultProps = {
  closable: true,
  fixedBanner: false,
};

function BannerAlert() {
  return (
    <div>
      <h1>Banner</h1>
    </div>
  );
}

BannerAlert.propTypes = propTypes;
BannerAlert.defaultProps = defaultProps;

export default BannerAlert;
