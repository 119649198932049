import {HOTJAR_HJID, HOTJAR_HJSV} from "./constants";

const hotjarScript = () => {
    const hjid = HOTJAR_HJID;
    const hjsv = HOTJAR_HJSV;

    (function (h, o, t, j, a, r) {
        // hotjar script
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); };
        h._hjSettings = { hjid, hjsv };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);

    }(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv='));
};

export default hotjarScript;