import React, { useContext } from 'react';
import { loader } from 'graphql.macro';
import {
  Form,
  Select,
} from 'antd';
import { useMutation } from '@apollo/client';
import { isObjectEmpty } from '../../utils/tools';
import './styles.scss';
import { ReactComponent as ArrowDownIcon } from '../../resources/icons/arrowDown.svg';
import { AppContext } from '../../contexts';

const updateUserProfileRequest = loader('../../views/Profile/requests/updateUserProfile.gql');

function LangPicker() {
  const { state, dispatch } = useContext(AppContext);
  const { me } = state;
  const { variables } = state;

  const [updateUserMutation] = useMutation(updateUserProfileRequest);

  const handleChange = (event) => {
    dispatch({
      type: 'updateVariables',
      data: {
        ...state,
        variables: {
          ...state.variables,
          languages: variables.languages,
          selectedLanguage: event,
        },
      },
    });
    if (!isObjectEmpty(me)) {
      updateUserMutation({
        variables: {
          inputData: {
            siteLanguage: event,
          },
        },
      });
      dispatch({ type: 'refetchInfo' });
    }
  };

  return (
    <Form
      layout="horizontal"
      className="lang-picker"
    >
      <Select
        onChange={handleChange}
        name="language"
        id="language"
        bordered={false}
        value={variables?.selectedLanguage || variables.languages[0]}
        options={variables?.languages}
        suffixIcon={
          <ArrowDownIcon className="icon" />
        }
        dropdownStyle={{ minWidth: 80 }}
      />
    </Form>
  );
}

export default LangPicker;
