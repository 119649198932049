import routesDictionary from '../../routes/routesDict';

export const getSplitRoute = (route) => route.split('/').slice(0, -1).join('/');

const persistentHeaders = [
  getSplitRoute(routesDictionary.contentProviderView.router),
  getSplitRoute(routesDictionary.productContentView.router),
];

export default persistentHeaders;
