import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import Loading from '../components/Loading';
import {
  getAuthToken,
  logout,
} from '../utils/tools';
import useMultipleQueries from '../customHooks/useMultipleQueries';

export const AppContext = React.createContext();

const initialState = {
  loading: true,
};

const actions = {
  default: () => (initialState),
  updateInfo: (action, state) => ({
    ...state,
    me: action.data.me,
    loading: action.data.loading,
    variables: action.data.variables,
  }),
  refetchInfo: (action, state, refetch) => {
    refetch();
    return state;
  },
  updateVariables: (action, state) => ({
    ...state,
    variables: action.data.variables,
    loading: action.data.loading,
  }),
  clearInfo: () => (initialState),
};

const globalSettingsQuery = loader('./globalSettings.gql');
const globalVariablesQuery = loader('./globalVariables.gql');

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  setLoginState: PropTypes.func.isRequired,
  isLogin: PropTypes.bool.isRequired,
};

function AppContextProvider(props) {
  const {
    children,
    setLoginState,
    isLogin,
  } = props;

  const authToken = getAuthToken();

  const queryData = useMultipleQueries([
    {
      query: globalSettingsQuery,
      options: {
        fetchPolicy: 'no-cache',
        skip: !authToken,
      },
    },
    {
      query: globalVariablesQuery,
      options: {
        fetchPolicy: 'no-cache',
      },
    },
  ]);

  const [
    {
      data, loading, error, refetch,
    },
    {
      data: globalVariablesData, loading: globalVariablesLoading,
    },
  ] = queryData;

  const reducer = (state, action = 'default') => actions[action.type](action, state, refetch);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isVariablesLoaded, setIsVariablesLoaded] = useState(false);
  useEffect(() => {
    if (error || (data && !data.me)) {
      logout(setLoginState);
    } else if (data && data.me && authToken && isVariablesLoaded) {
      dispatch({
        type: 'updateInfo',
        data: {
          ...state,
          me: data.me,
          variables: {
            ...state.variables,
            selectedLanguage: data.me.siteLanguage?.id,
          },
        },
      });
    }
  // eslint-disable-next-line
  }, [data, error, authToken, setLoginState, globalVariablesData, isVariablesLoaded]);

  useEffect(() => {
    if (globalVariablesData) {
      const languages = globalVariablesData
        .getLanguages.map((item) => ({ value: item.id, label: item.name }));
      const categories = globalVariablesData
        .getCategories.map((item) => ({ value: item.id, label: item.name }));
      dispatch({
        type: 'updateVariables',
        data: {
          ...state,
          variables: {
            ...state.variables,
            languages,
            categories,
            ...!isLogin && { selectedLanguage: languages[0]?.value },
          },
        },
      });
      setIsVariablesLoaded(true);
    }
  }, [globalVariablesData]);

  if (loading || globalVariablesLoading || (isLogin && !state.me)) return <Loading />;

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

AppContextProvider.propTypes = propTypes;

export default AppContextProvider;
