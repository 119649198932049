import { lazy } from 'react';
import routesDictionary from './routesDict';

const AsyncLogin = lazy(() => import('../views/Auth/Login'));
const AsyncRegister = lazy(() => import('../views/Auth/Register'));
const AsyncEmailVerification = lazy(() => import('../views/Auth/EmailVerification'));
const AsyncHome = lazy(() => import('../views/Home'));
const AsyncVerificationResend = lazy(() => import('../views/Auth/EmailVerification/resend'));
const AsyncPasswordChange = lazy(() => import('../views/Auth/Password/RequestReset'));
const AsyncResetPassword = lazy(() => import('../views/Auth/Password/Reset'));
const AsyncGoogleAuth = lazy(() => import('../views/Auth/Social/Google'));
const AsyncGithubAuth = lazy(() => import('../views/Auth/Social/Github'));
const AsyncProfile = lazy(() => import('../views/Profile'));
const AsyncProductContentCreate = lazy(() => import('../views/ProductContent/ProductContentEdit'));
const AsyncProductContentEdit = lazy(() => import('../views/ProductContent/ProductContentEdit'));
const AsyncProductContentOwn = lazy(() => import('../views/ProductContent/ProductContentOwn'));
const AsyncProductContentView = lazy(() => import('../views/ProductContent/ProductContentView'));
const AsyncProductContentVerification = lazy(() => import('../views/ProductContent/ProductContentVerification'));
const AsyncContentProviderView = lazy(() => import('../views/ContentProvider/ContentProviderView'));

const publicRoutes = [
  {
    path: routesDictionary.githubAuth.router,
    component: AsyncGithubAuth,
    type: 'public',
    title: routesDictionary.githubAuth.title,
  },
  {
    path: routesDictionary.googleAuth.router,
    component: AsyncGoogleAuth,
    type: 'public',
    title: routesDictionary.googleAuth.title,
  },
  {
    path: routesDictionary.register.router,
    component: AsyncRegister,
    type: 'public',
    title: routesDictionary.register.title,
  },
  {
    path: routesDictionary.login.router,
    component: AsyncLogin,
    type: 'public',
    title: routesDictionary.login.title,
  },
];

const privateRoutes = [
  {
    path: routesDictionary.productContentVerification.router,
    component: AsyncProductContentVerification,
    type: 'private',
    title: routesDictionary.productContentVerification.title,
    module: routesDictionary.productContentVerification.module,
  },
  {
    path: routesDictionary.productContentOwn.router,
    component: AsyncProductContentOwn,
    type: 'private',
    title: routesDictionary.productContentOwn.title,
    module: routesDictionary.productContentOwn.module,
  },
  {
    path: routesDictionary.productContentEdit.router,
    component: AsyncProductContentEdit,
    type: 'private',
    title: routesDictionary.productContentEdit.title,
    module: routesDictionary.productContentEdit.module,
  },
  {
    path: routesDictionary.productContentCreate.router,
    component: AsyncProductContentCreate,
    type: 'private',
    title: routesDictionary.productContentCreate.title,
    module: routesDictionary.productContentCreate.module,
  },
  {
    path: routesDictionary.profile.router,
    component: AsyncProfile,
    type: 'private',
    title: routesDictionary.profile.title,
    module: routesDictionary.profile.module,
  },

];

const persistentRoutes = [
  {
    path: routesDictionary.productContentView.router,
    component: AsyncProductContentView,
    type: 'persistent',
    title: routesDictionary.productContentView.title,
    module: routesDictionary.productContentView.module,
  },
  {
    path: routesDictionary.contentProviderView.router,
    component: AsyncContentProviderView,
    type: 'persistent',
    title: routesDictionary.contentProviderView.title,
    module: routesDictionary.contentProviderView.module,
  },
  {
    path: routesDictionary.resetPassword.router,
    component: AsyncResetPassword,
    type: 'persistent',
    title: routesDictionary.resetPassword.title,
  },
  {
    path: routesDictionary.changePassword.router,
    component: AsyncPasswordChange,
    type: 'persistent',
    title: routesDictionary.changePassword.title,
  },
  {
    path: routesDictionary.verificationResent.router,
    component: AsyncVerificationResend,
    type: 'persistent',
    title: routesDictionary.verificationResent.title,
  },
  {
    path: routesDictionary.emailVerification.router,
    component: AsyncEmailVerification,
    type: 'persistent',
    title: routesDictionary.emailVerification.title,
  },
  {
    path: routesDictionary.home.router,
    component: AsyncHome,
    type: 'persistent',
    title: routesDictionary.home.title,
    module: routesDictionary.home.module,
  },
];

const routes = [...privateRoutes, ...publicRoutes, ...persistentRoutes];

export default routes;
