import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import {
  isNull,
  getAuthToken,
  // logout,
  getExpiredRefreshToken,
} from '../../utils/tools';
import Apollo from '../Apollo';
// import banner from '../../utils/banner';
// import './styles.scss';

// let time;

const baseStates = {
  loading: () => null,
  component: (props) => (<Apollo {...props} />),
};

function Base() {
  // const { t } = useTranslation();
  const [loginInternal, setLoginInternal] = useState(
    {
      isLogin: !isNull(getAuthToken()),
      hasCookie: !isNull(getExpiredRefreshToken()),
    },
  );

  const setLoginState = (newState, cookie = false) => {
    setLoginInternal({
      isLogin: newState,
      hasCookie: cookie,
    });
  };

  const componentProps = {
    isLogin: loginInternal.isLogin,
    setLoginState,
  };

  return (baseStates.component(componentProps));
}

export default Base;
