import React, {useEffect} from 'react';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import Base from './wrappers/Base';
import './styles/utils.scss';
import './styles/overwrite.scss';
import 'antd/dist/reset.css';
import './styles/index.scss';
import 'react-quill/dist/quill.snow.css';
import hotjarScript from "./settings/hotjar_script";

function App() {
    useEffect(() => {
        hotjarScript();
    }, []);
  return (
    <BrowserRouter>
      <Base />
    </BrowserRouter>
  );
}

export default App;
