import { useEffect, useState } from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const useCurrentBreakpoint = () => {
  const screens = useBreakpoint();
  const [breakpoint, setBreakpoint] = useState('');

  useEffect(() => {
    Object.entries(screens)
      .filter((screen) => screen[1])
      .map((screen) => setBreakpoint(screen[0]));
  }, [screens]);

  return breakpoint;
};

export default useCurrentBreakpoint;
