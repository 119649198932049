import React, { useContext } from 'react';
import {
  Navigate,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import routesDictionary from './routesDict';
import { getTranslatorSchema, logout } from '../utils/tools';
import { AppContext } from '../contexts';
import useQueryParams from '../customHooks/useQueryParams';
import { permissions } from './constants';

const routeTypes = {
  public: (props) => {
    const {
      renderProps,
      component: Component,
      isLogin,
      setLoginState,
      queryString,
    } = props;

    if (isLogin) {
      return (
        <Navigate
          // to={queryString.get('next') || routesDictionary.home.router}
          to={routesDictionary.home.router}
        />
      );
    }

    return <Component setLoginState={setLoginState} {...renderProps} />;
  },
  private: (props) => {
    const {
      renderProps,
      component: Component,
      isLogin,
      setLoginState,
      location,
      state,
      path,
      module,
    } = props;

    if (!isLogin) {
      return (
        <Navigate
          to={routesDictionary.login.router}
          // to={{
          //   pathName: routesDictionary.login.router,
          //   search: `next=${location.pathname}`,
          // }}
        />
      );
    }

    const { me } = state;

    if (!permissions(me.isApprover, me.isStaff).includes(module)) {
      return (
        <Navigate
          to={routesDictionary.home.router}
          replace
        />
      );
    }

    return (
      <Component
        setLoginState={setLoginState}
        {...renderProps}
      />
    );
  },
  persistent: (props) => {
    const {
      renderProps,
      component: Component,
      setLoginState,
    } = props;
    // logout(setLoginState);
    return <Component setLoginState={setLoginState} {...renderProps} />;
  },
};

const MakeRouteWithSubRoutes = function (props) {
  const { t } = useTranslation();
  const translator = getTranslatorSchema('viewTitles', t);
  const { state } = useContext(AppContext);
  const location = useLocation();
  const queryString = useQueryParams();

  const {
    path,
    exact,
    title,
    name,
    component: Component,
    type,
    isLogin,
    setLoginState,
    module,
  } = props;

  window.scrollTo(0, 0);
  document.title = translator(`viewTitles.${title}`, true);
  return (
    routeTypes[type]({
      setLoginState,
      component: Component,
      isLogin,
      state,
      location,
      queryString,
      module,
    })
  );
};

MakeRouteWithSubRoutes.propTypes = {
  /**
   * The route path
   */
  path: PropTypes.string.isRequired,
  /**
   * Component to be rendered
   */
  component: PropTypes.elementType.isRequired,
  /**
   * If path will be an exact match
   */
  exact: PropTypes.bool,
  /**
   * Path type private, public, persistent
   */
  type: PropTypes.string,
  /**
   * If user is Logged
   */
  isLogin: PropTypes.bool,
  /**
   * Function to change application state to login
   */
  setLoginState: PropTypes.func.isRequired,
  /**
   * Tab Title
   */
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  module: PropTypes.string,
};

MakeRouteWithSubRoutes.defaultProps = {
  exact: false,
  type: 'private',
  isLogin: false,
  name: '',
};

export default MakeRouteWithSubRoutes;
