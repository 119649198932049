import React from 'react';
import { Layout, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getTranslatorSchema } from '../../utils/tools';

const { Footer: AntdFooter } = Layout;

function Footer() {
  const { t } = useTranslation();
  const translator = getTranslatorSchema('globals', t);
  return (
    <AntdFooter className="footer">
      <Row className="result-view__footer" justify="center">
        <span>{translator('copyright', true)}</span>
      </Row>
    </AntdFooter>
  );
}

export default Footer;
