import { useQuery } from '@apollo/client';

const useMultipleQueries = (queries) => {
  const queryData = [];
  queries.forEach(({ query, options }) => {
    queryData.push(useQuery(query, options));
  });
  return queryData;
};

export default useMultipleQueries;
