const routesDictionary = {
  contentProviderView: {
    router: '/content-provider/view/:contentProviderId',
    title: 'contentProvider',
    func: (contentProviderId) => `/content-provider/view/${contentProviderId}`,
    module: 'contentProvider',
  },
  productContentVerification: {
    router: '/product-content/verification',
    title: 'productContent',
    module: 'productContentVerification',
  },
  productContentView: {
    router: '/product-content/view/:productContentId',
    title: 'productContent',
    func: (productContentId) => `/product-content/view/${productContentId}`,
    module: 'productContent',
  },
  productContentOwn: {
    router: '/product-content/own',
    title: 'productContent',
    module: 'productContentOwn',
  },
  productContentEdit: {
    router: '/product-content/edit/:productContentId',
    title: 'productContent',
    func: (productContentId) => `/product-content/edit/${productContentId}`,
    module: 'productContent',
  },
  productContentCreate: {
    router: '/product-content/create',
    title: 'productContent',
    module: 'createProductContent',
  },
  profile: {
    router: '/profile',
    title: 'profile',
    module: 'profile',
  },
  githubAuth: {
    router: '/social-auth/github',
    title: 'githubAuth',
  },
  googleAuth: {
    router: '/social-auth/google',
    title: 'googleAuth',
  },
  resetPassword: {
    router: '/password/reset/:uid/:token',
    title: 'resetPassword',
  },
  changePassword: {
    router: '/password/change',
    title: 'forgotPassword',
  },
  verificationResent: {
    router: '/email-verification/resend',
    title: 'emailVerification',
  },
  register: {
    router: '/register',
    title: 'register',
  },
  emailVerification: {
    router: '/verifyEmail/:uid/:token',
    title: 'emailVerification',
  },
  login: {
    router: '/login',
    title: 'login',
  },
  home: {
    router: '/',
    title: 'home',
    module: 'home',
  },
};

export default routesDictionary;
