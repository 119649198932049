import React from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getTranslatorSchema } from '../../../utils/tools';
import routesDictionary from '../../../routes/routesDict';

function AuthHeader() {
  const { t } = useTranslation();
  const translator = getTranslatorSchema('header', t);
  const navigate = useNavigate();

  return (
    <Row gutter={[16, 0]} justify="end">
      <Col>
        <Button onClick={() => navigate(routesDictionary.register.router)}>
          {translator('register')}
        </Button>
      </Col>
      <Col>
        <Button type="primary" onClick={() => navigate(routesDictionary.login.router)}>
          {translator('login')}
        </Button>
      </Col>
    </Row>
  );
}

export default AuthHeader;
