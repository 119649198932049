const resourceEn = {
  translation: {
    global: {
      viewTitles: {
        register: 'Register',
        emailVerification: 'Email Verification',
        login: 'Login',
        home: 'Home',
        forgotPassword: 'Forgot password',
        resetPassword: 'Reset password',
        profile: 'Profile',
        productContent: 'Content',
        githubAuth: 'Github Auth',
        googleAuth: 'Google Auth',
        contentProvider: 'Content provider',
      },
      loading: 'Loading',
      copyright: '© Copyright 2023 - DORAEMART',
      errors: {
        validEmail: 'Not a valid email address',
        requiredEmail: 'Please input your email!',
        requiredPassword: 'Please input your password!',
        requiredFirstName: 'Please input your firstname!',
        requiredLastname: 'Please input your lastname!',
        requiredConfirmPassword: 'Please confirm your password!',
        passwordsNotMatch: 'Passwords do not match!',
        validString: 'Invalid characters',
        wrongAddress: 'Wrong address',
        required: 'This field is required.',
        wrongPhoneNumber: 'Wrong phone number',
        validUrl: 'Incorrect URL is not exist',
        maximumLines: 'This field cannot have more than {{size}} lines.',
        validPrice: 'Price must be greater than 0',
      },
    },
    loginView: {
      title: 'Login',
      description: '',
      createNewAccount: 'Create new account',
      youHaveAnAccount: 'You have an account ?',
      email: 'Email',
      password: 'Password',
      remember: 'Remember account',
      forgetYourPassword: 'Did you forget your password?',
      loginWith: 'Login with',
      login: 'Login',
    },
    registerView: {
      title: 'Create new account',
      description: '',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      password: 'Password',
      create: 'Create',
      registerWith: 'Register with',
      haveAccount: 'If you already have an account',
      login: 'Login',

      confirmEmail: 'Please confirm your email address',
      confirmEmailDescription: '',
      backText: 'Back to Home Page',
      helpText: 'Didn\'t receive the confirmation email? Click here to resend it.',
      button2Text: 'Resend confirmation message',
      isApprover: 'Is approver',
      userType: 'User type',
      selectOption: 'Select option',
      companyName: 'Company Name',
      helpApprover: '',
      approver: 'Approver',
    },
    resendConfirmationView: {
      title: 'Resend Verification',
      description: 'Enter the email address associated with your account and we\'ll send your verification message again.',
      send: 'Send',
      haveAccount: 'If you already have an account',
      backHome: 'Back to Home Page',
      email: 'Email',

      sendEmailTitle: 'It will be sent to the mail',
      sendEmailDescription: '',
    },
    emailConfirmation: {
      title: 'E-mail confirmation!',
      description: '',
      backLogin: 'Go back to login page',

      expiredTitle: 'Link Expired',
      expiredDescription: '',
      helpText: 'Didn\'t receive the confirmation email? Click here to resend it.',
      resendMessage: 'Resend confirmation message',
      tryAgain: 'Try again',
    },
    changePasswordView: {
      title: 'Forgot Password',
      description: 'Enter the email address associated with your account and we\'ll send you a link to reset your password.',
      email: 'Email',
      send: 'Send',
      ok: 'Ok',

      sendEmailTitle: 'It will be sent to the mail',
      sendEmailDescription: '',
    },
    resetPasswordView: {
      title: 'Change your Password',
      description: '',
      confirm: 'Confirm',
      backLogin: 'Go back to login page',

      expiredTitle: 'Link Expired',
      expiredDescription: '',

      newPassword: 'New Password',
      confirmNewPassword: 'Confirm New Password',

      passwordUpdated: 'Password update!',
      passwordUpdatedDescription: 'Your password has been changed successfully! Use your new password to...',
      login: 'Login',
      tryAgain: 'Try again',
    },
    resultView: {
      haveAccount: 'If you already have an account ',
      login: 'Login',
    },
    header: {
      welcome: 'Welcome!',
      profileSettings: 'Profile Settings',
      // productContent: 'Product Content',
      createContent: 'Create Content',
      logout: 'Log out',
      myContentProducts: 'My Contents',
      productVerification: 'Content Verification',
      login: 'Login',
      register: 'Register',
    },
    profileView: {
      profileSettings: 'Profile Settings',
      account: 'Account',
      cancel: 'Cancel',
      save: 'Save',
      email: 'Email',
      password: 'Password',
      basicInformation: 'Basic information',
      name: 'Name',
      lastName: 'Last Name',
      companyName: 'Company Name',
      companyWebsite: 'Company Website',
      address: 'Address',
      languageLocation: 'Language / Location',
      location: 'Location',
      phoneNumber: 'Phone number',
      phoneNumberPlaceholder: '+14155552671',
      siteLanguage: 'Site language',
      externalLinks: 'External links',
      linkedinURL: 'Linkedin URL',
      githubURL: 'Github URL',
      settings: 'Settings',
      storageProviderID: 'Storage Provider ID',
      profile: 'Profile',
      aboutMe: 'ABOUT ME',
      newTextAboutMe: 'New text about me',
      profileUpdate: 'Profile update!',
      profileUpdateDescription: 'Your profile has been changed successfully!',
      wrongProfileUpdate: 'Wrong profile update',
      wrongProfileUpdateDescription: 'Your profile could not be changed!',
      accept: 'Accept',
      passwordPlaceholder: 'New Password',
      socialPasswordPlaceholder: '**************',
      invalidImage: '{{fileName}} is not a image file',
      invalidImageSize: 'Image file too large ( > 1mb )',
      invalidImageResolution: 'Low image resolution. It should be at least 250x250 px',
      helpImageSize: 'Image size must be less than 1 mb.',
      helpImageResolution: 'The resolution of the image must be at least 250x250 px.',
      isApprover: 'Is approver',
      contentLanguages: 'Content Languages',
      helpApprover: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
      approver: 'Approver',
    },
    sectionInputComponent: {
      googleRegister: 'Your registration was made with a Gmail account...',
      githubRegister: 'Your registration was made with a Github account...',
      save: 'save',
    },
    productContentView: {
      productContent: 'Content',
      image: 'Image',
      product: 'Content',
      cancel: 'Cancel',
      save: 'Save',
      name: 'Name',
      basicInformation: 'Basic Information',
      timeToDelivery: 'Time to delivery',
      location: 'Location',
      category: 'Category',
      price: 'Price',
      description: 'Description',
      invalidImage: '{{fileName}} is not a image file',
      descriptionImage: 'Lorem ipsum dolor sit <strong>600 x 500</strong> amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore',
      longDescription: 'Long description',
      shortDescription: 'Short description',
      language: 'Language',
      videoLinks: 'Video links',
      url: 'url',
      addVideoLink: 'Add video link',
      productContentCreate: 'Content created!',
      productContentCreateDescription: 'The content was created successfully.',
      wrongProductContentCreate: 'Wrong content create!',

      productContentUpdate: 'Content updated!',
      productContentUpdateDescription: 'The content was updated successfully',
      wrongProductContentUpdate: 'Wrong content update!',

      update: 'Update',
      tags: 'Tags',
      startApprovalProcess: 'Start approval',
      confirmationTitle: 'Start approval process',
      confirmationDescription: 'Are you sure you want to start the approval process for this content?',

      approvalProcessStarted: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
      approvalProcess: 'Content approval process',
      urlLink: 'URL Link',
    },
    dynamicFormComponent: {
      videoLink: 'Video link',
    },
    homeView: {
      searchProduct: 'Search Content',
      searchTag: 'Search Tag',
      filters: 'Filters',
      categories: 'Categories',
      tags: 'Tags',
      contentProvider: 'Content Provider',
      search: 'Search',
      companyName: 'Company Name',
    },
    socialLoginView: {
      completeAccount: 'Complete your account',
      description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
      isApprover: 'Is approver',
      userType: 'User type',
      selectOption: 'Select option',
    },
    keywordsModal: {
      title: 'Filter by Category',
      addKeywords: 'Add Tags',
      cancel: 'Cancel',
      accept: 'Accept',
    },
    productContentList: {
      myContentProducts: 'My Contents',
      productVerification: 'Content Verification',
      inDraft: 'In draft',
      pendingApproval: 'Pending Approval',
      approved: 'Approved',
      reject: 'Rejected',
      all: 'All',
      description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore',
      createContent: 'Create Content',
    },
    productStatusCard: {
      edit: 'Edit',
      delete: 'Delete',
      title: 'Delete content',
      description: 'Are you sure you want to delete this content?',
      approved: 'Approved',
      rejected: 'Rejected',
      draftTitle: 'Edit content',
      draftDescription: 'The content will revert to draft status and you will have to start the approval process again.',
    },
    productContentDetailsView: {
      title: 'Content Information',
      orderDetails: 'Order Details',
      pricing: 'Pricing',
      description: 'Description',
      videos: 'Videos',
      approved: 'Approved',
      rejected: 'Rejected',
      order: 'Order',
      rating: 'Rating',
      aboutContentProvider: 'About the Content Provider',
      name: 'Name',
      contact: 'Contact',
      tags: 'Tags',
      successDescription: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,',
      url: 'Url',
      companyDetails: 'Company Details',
      companyWebsite: 'Company Website',
      companyName: 'Company Name',
    },
    productStatusModal: {
      answer: 'Answer',
      approved: 'Approved',
      approveDescription: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod?',
      rejected: 'Rejected',
      rejectDescription: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod?',
      cancel: 'Cancel',
      accept: 'Accept',
      successDescription: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,',
    },
    contentProviderView: {
      websiteContentProvider: 'Website Content Provider',
      shortIntro: 'Short Intro',
      pastOrder: 'Past Order',
      orderDetails: 'Oder Details',
      contact: 'Contact',
      rating: 'Rating',
      aboutContentProvider: 'About the Content Provider',
      tags: 'Tags',
      loremIpsum: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur',
      products: 'Contents',
    },
    orderModal: {
      title: 'Order',
      description: 'Complete your order by filling in the following information',
      email: 'Email',
      phoneNumber: 'Phone Number',
      phoneNumberPlaceholder: '+14155552671',
      message: 'Message',
      accept: 'Accept',
      orderSent: 'Order sent',
      thankYou: 'Thank you for your order in Doraemart',
      orderNotSended: 'Order not sended',
    },
  },
};

export default resourceEn;
