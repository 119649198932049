import React from 'react';
import {
  ApolloProvider,
  concat,
} from '@apollo/react-hooks';
import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import {
  getAuthToken,
  getBackLanguage,
} from '../../utils/tools';
import {
  API_URL,
} from '../../settings/constants';
import { refreshTokenLink } from './refresh';
import Router from '../Routing';

function customFetch(url, opts = {}) {
  const authToken = getAuthToken();

  // eslint-disable-next-line no-param-reassign
  opts.headers.authorization = authToken ? `JWT ${authToken}` : '';
  // eslint-disable-next-line no-param-reassign
  opts.headers['Accept-Language'] = getBackLanguage();

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(opts.method || 'get', url);

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);

    xhr.onload = (e) => resolve({
      ok: true,
      text: () => Promise.resolve(e.target.responseText),
      json: () => Promise.resolve(JSON.parse(e.target.responseText)),
    });

    xhr.onerror = reject;

    xhr.send(opts.body);
  });
}

const httpLink = (setLoginState) => concat(
  refreshTokenLink(setLoginState),
  createUploadLink({
    uri: API_URL,
    fetch: typeof window === 'undefined' ? global.fetch : customFetch,
  }),
);

const cache = new InMemoryCache();

const client = (setLoginState) => new ApolloClient({
  link: httpLink(setLoginState),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'no-cache',
    },
    mutate: {
      errorPolicy: 'no-cache',
    },
  },
});

function Apollo(props) {
  const { setLoginState } = props;

  return (
    <ApolloProvider client={client(setLoginState)}>
      <Router {...props} />
    </ApolloProvider>
  );
}

export default Apollo;
