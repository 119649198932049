import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
  Spin,
} from 'antd';
import './styles.scss';

function Loading(props) {
  const { className } = props;
  return (
    <Row className={`loading vh-100 ${className}`} justify="center" align="middle">
      <Col>
        <Spin size="large" indicator={<LoadingOutlined spin />} />
      </Col>
    </Row>
  );
}

export default Loading;
